<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <transition name="fade" mode="out-in">
      <vs-button v-if="showForm" @click="onCreate()">{{ $t("shops.back") }}</vs-button>

      <div class="mb-base" v-if="!showForm">
        <data-table
          :data="tableData"
          :options="tableOptions"
          @create="onCreate"
          @remove="onRemove($event, 'shops.product')"
          @update="onUpdate"
          @sort="onSort"
          @search="onSearch"
          @change-page="onTablePageChange"
          @per-page-change="onPerPageChange"
          @date-range-search="onDateRangeSearch"
          @date-range-clear="onDateRangeClear"
        />
      </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <product-form v-model="formData" @submit="onProductFormSubmit" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import ProductForm from "@/modules/Shops/Pages/forms/ProductForm.vue";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";

export default {
  name: "Products",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    DataTable,
    ProductForm,
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  async created() {
    this.$store.commit("shops/products/RESET_RESOURCE");
    this.$store.commit("shops/catalogs/RESET_RESOURCE");
    this.load();
    await this.loadParticipants({embed: "all"})
  },

  computed: {
    ...mapGetters("shops/products", {
      productList: "getAll",
      catalogProducts: "getByCatalogId",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      productById: "getById",
    }),

    ...mapGetters("shops/catalogs", {
      catalogs: "getAll",
      catalogById: "getById",
    }),

    ...mapGetters("shops/product_types", {
      product_types: "getAll"
    }),

    ...mapGetters("shops/taxes", {
      taxes: "getAll"
    }),

    ...mapGetters("shops/participants", {
      participants: "getAll"
    }),

    tableData() {
      let list = this.productList;
      return list.map(this.productToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: true,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/products", {
      loadProducts: "load",
      removeData: "remove",
      updateProduct: "update",
      createProduct: "create"
    }),
    ...mapActions("shops/product_types", { loadProductTypes: "load" }),
    ...mapActions("shops/catalogs", { loadCatalogs: "load" }),
    ...mapActions("shops/taxes", { loadTaxes: "load"}),
    ...mapActions("shops/participants", { loadParticipants: "load"}),

    productToTableData(product) {
      let currCatalog;
      if (product.catalogs) {
        currCatalog = product.catalogs[0] === undefined ? "" : product.catalogs[0].name;
      } else {
        currCatalog = ""
      }
      return {
        id: product.id,
        active: product.active,
        name: product.name,
        description: product.description,
        type: product.type.code,
        catalog: currCatalog,
        external_id: product.external_id,
        created_at: product.created_at,
        updated_at: product.updated_at,
      };
    },

    async loadData(params) {
      await this.loadProducts(params)
      if (this.catalogs.length <= 0) await this.loadCatalogs({ embed: "all" });
      if (this.product_types.length <= 0) await this.loadProductTypes();
      if (this.taxes.length <= 0) await this.loadTaxes();
    },

    async onProductFormSubmit(product) {
      try {
        if (product.id === 0) {
          await this.createProduct(product);
          this.notifySuccess(
            i18n.t("shops.save"),
            `${i18n.t('shops.product')} ${this.formData.name} ${i18n.t('shops.has_been_created_successfully')}`
          );
        } else {
          await this.updateProduct(product);
          this.notifySuccess(
            i18n.t("shops.updated"),
            `${i18n.t('shops.product')} ${product.name} ${i18n.t('shops.has_been_updated_successfully')}`
          );
        }
        this.formData = this.default();
        this.showForm = false;

      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_Product"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onUpdate(product) {
      const translationsDefault = this.translationsDefault(['name', 'description'])

      this.formData = Object.assign(this.formData, {
        ...this.productById(product.id),
        translations: { ...translationsDefault, ...this.productById(product.id).translations }
      });

      this.formData.catalogs = { name: product.catalog };
      if (this.formData.tax == undefined) this.formData.tax = { name: "", id: "" };

      await this.$store.dispatch("shops/products/assignTranslations", this.formData);

      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        active: false,
        catalogs: [],
        stock: 0,
        external_id: "",
        translations: this.translationsDefault(['name', 'description']),
        name: "",
        description: "",
        type: {},
        participant: {},
        product: {},
        restrictions: {
          friday: true,
          monday: true,
          saturday: true,
          sunday: true,
          thursday: true,
          tuesday: true,
          wednesday: true,
        },
        requirements: {
          date: false,
          keycard: false,
          photo: false,
          participant: null,
          product: null
        },
        tax: {
          id: 0,
          name: ""
        },
        days: 0
      }
    }
  }
};
</script>

<style>
.danger-border div,
.danger-border-select div input {
  border-color: red !important;
}
</style>
