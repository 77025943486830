<template>
  <div class="product-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/2 mb-5">
        <label class="vs-input--label">{{ $t("shops.active") }}</label>
        <vs-switch class="mt-3" v-model="product.active" type="boolean" />
      </div>
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="product.name"
          v-validate="'required'"
          class="w-full"
          name="productName"
          :danger="errorProductName && errors.has('productName')"
          :danger-text="$t('shops.errors.name_is_required')" />
        <vs-input v-else v-model="product.translations[language].name" class="w-full" :name="`productName${language}`"/>
      </div>
    </div>

    <div class="vx-row mb-6 mt-10">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t(`shops.description_${language}`) }}</label>
        <vs-input v-if="langRequired(language)" class="w-full" v-model="product.description" name="productDescription" />
        <vs-input v-else class="w-full" v-model="product.translations[language].description" :name="`productDescription${language}`" />
      </div>
    </div>
  
    <div class="vx-row mb-6 mt-10">
      <div class="vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.external_id_info") }}</label>
        <vs-input class="w-full" v-model="product.external_id" name="productExternalID" />
      </div>

      <div class="mb-6 vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.catalog.0") }}</label>
        <v-select
          v-if="catalogList.length > 0"
          v-model="product.catalogs.name"
          :options="catalogList.map((catalog) => ({ label: catalog.name, value: catalog.id }))"
          @input="(option) => selectCatalog(option)"
          class="w-full select-auto"
          :class="{'danger-border-select': errorCatalog && errors.has('catalog')}"
          :value="actualCatalog"
          v-validate="'required'"
          :danger-text="$t('shops.errors.catalog_is_required')"
          :danger="errorCatalog && errors.has('catalog')"
          name="catalog"
          :placeholder="$t('shops.select_a_catalog')"
        />
      </div>

      <div class="mb-6 vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.type") }}</label>
        <v-select
          v-if="productTypes.length > 0"
          v-model="product.type.name"
          :options="productTypes.map((productType) => ({ label: productType.name, value: productType.id }))"
          @input="(option) => selectProductType(option)"
          class="w-full select-large"
          :class="{'danger-border-select': errorProductType && errors.has('product_type')}"
          :value="product.type.id"
          name="product_type"
          :placeholder="$t('shops.select_a_product_type')"
          v-validate="'required'"
          :danger-text="$t('shops.errors.product_type_is_required')"
          :danger="errorProductType && errors.has('type')"
        />
      </div>

      <div class="mb-6 vx-col w-1/5">
        <label class="vs-input--label">{{ $t("shops.tax") }}</label>
        <v-select
            v-if="taxList.length > 0"
            v-model="product.tax.name"
            :options="taxList.map((tax) => ({ label: tax.name, value: tax.id }))"
            @input="(option) => selectTax(option)"
            class="w-full select-large"
            :class="{'danger-border-select': errorTax && errors.has('tax')}"
            :value="value.tax.name"
            name="tax"
            :placeholder="$t('shops.select_a_tax')"
            v-validate="'required'"
            :danger-text="$t('shops.errors.tax_is_required')"
            :danger="errorTax && errors.has('tax')"
          />
      </div>

      <div class="mb-6 vx-col w-1/5" v-if="showDaysDuration">
        <label class="vs-input--label">{{ $t("shops.days") }}</label>
        <vs-input-number v-model="product.days" name="days" />
      </div>
    </div>

    <div class="mb-6 vx-col w-full">
      <div class="w-full">
        <label class="vs-input--label"><b>{{ $t("shops.days_on_sale") }}</b></label>
      </div>
      <div class="vx-row mb-6">
        <div v-for="(day, index) in weekdays" :key="index" class="vx-col w-1/7">
          <label class="vs-input--label">{{ $t(`shops.${day}`) }}</label>
          <vs-switch class="mt-3" v-model="product.restrictions[day]" type="boolean" />
        </div>
      </div>
    </div>

    <div class="mb-6 vx-col w-full">
      <div class="w-full">
        <label class="vs-input--label"><b>{{ $t("shops.requirements") }}</b></label>
      </div>
      <div class="vx-row mb-6">
        <div class="vx-col w-1/7">
          <label class="vs-input--label">{{ $t("shops.needs_date") }}</label>
          <vs-switch class="mt-3" v-model="product.requirements.date" type="boolean"/>
        </div>
        <div class="vx-col w-1/7">
          <label class="vs-input--label">{{ $t("shops.needs_keycard") }}</label>
          <vs-switch class="mt-3" v-model="product.requirements.keycard" type="boolean"/>
        </div>
        <div class="vx-col w-1/7">
          <label class="vs-input--label">{{ $t("shops.needs_photo") }}</label>
          <vs-switch class="mt-3" v-model="product.requirements.photo" type="boolean"/>
        </div>
      </div>
    </div>

    <div v-if="product.type.id === 3 || product.product_type_id === 3">
      <div class="mb-6 vx-col w-full">
        <div class="w-full">
          <label class="vs-input--label"><b>{{ $t("shops.products.title") }}</b></label>
        </div>
        <div class="inline-block">
          <v-select multiple
            class="minwidthSelect"
            v-model="product_extras"
            :options="productsFiltered"
            :placeholder="$t('shops.select_extras')"
            />
        </div>
        <div class="pt-3">
          <vs-button @click="selectAllExtras" type="filled">{{$t("shops.select_all")}}</vs-button>
          <vs-button @click="deselectAllExtras" class="ml-4" type="filled">{{$t("shops.deselect_all")}}</vs-button>
        </div>
      </div>
      <div class="mb-6 vx-col w-full">
        <div class="w-full">
          <label class="vs-input--label"><b>{{ $t("shops.participants.title") }}</b></label>
        </div>
        <div class="inline-block">
          <v-select multiple
            class="minwidthSelect"
            v-model="product_participants"
            :options="participantsFiltered"
            :placeholder="$t('shops.select_participants')"
            />
        </div>
        <div class="pt-3">
          <vs-button @click="selectAllParticipants" type="filled">{{$t("shops.select_all")}}</vs-button>
          <vs-button @click="deselectAllParticipants" class="ml-4" type="filled">{{$t("shops.deselect_all")}}</vs-button>
        </div>
      </div>
    </div>
    <div class="vx-col 1/3">
      <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import { weekdays } from "@/modules/Shared/Helpers/dateHelper.js"

export default {
  name: "ProductForm",

  mixins: [ notifications, shopLanguages ],

  props: {
    value: { type: Object, default: null }
  },

  data() {
    return {
      actualCatalog: {},
      errorProductName: false,
      errorProductNameFR: false,
      errorProductNameDE: false,
      errorProductNameES: false,
      errorProductNameNO: false,
      errorProductNameIT: false,
      errorCatalog: false,
      errorProductType: false,
      errorProductStock: false,
      errorTax: false,
      errorProduct: false,
      errorParticipant: false,
      product_extras: [],
      product_participants: [],
      product: {},
      weekdays: [] 
    };
  },

  computed: {
    ...mapGetters(['applicationLanguage']),
    ...mapGetters("shops/product_types", {
      productTypes: "getAll"
    }),
    ...mapGetters("shops/catalogs", {
      catalogList: "getAll"
    }),
    ...mapGetters("shops/taxes", {
      taxList: "getAll"
    }),
    ...mapGetters("shops/participants", {
      participantList: "getAll",
      participantById: "getById"
    }),
    ...mapGetters("shops/products", {
      productList: "getAll",
      productById: "getById"
    }),
    showDaysDuration() {
      return this.product.requirements.date && (this.product.type.code == 'ticket' || this.product.type.name.value == 1)
    },
    productsFiltered() {
      return this.product.type.id === 3 || this.product.product_type_id === 3
        ? this.productList.filter((product) => product.type.id === 1 && (product.catalogs[0].catalog_id === this.product.catalog_id || product.catalogs[0].catalog_id === this.actualCatalog.id))
          .map((product) => ({ label: product.name, value: product.id }))
        : []
    },
    participantsFiltered() {
      return this.product.type.id === 3 || this.product.product_type_id === 3
        ? this.participantList.filter((participant) => participant.catalogs[0].catalog_id === this.product.catalog_id || participant.catalogs[0].catalog_id === this.actualCatalog.id)
          .map((participant) => ({ label: participant.name, value: participant.id }))
        : []
    }
  },

  methods: {
    validateAndSubmit() {
      const payload = {...this.languages().reduce((carry, lang) => {
        carry[lang] = this.product.translations[lang]
        return carry
      }, {}), ...this.product}

      this.$validator.validateAll().then((result) => {
        if (result) {
          if (payload.participant.name === null) { payload.requirements.participant = null }
          if (payload.product.name === null) { payload.requirements.product = null }

          payload.catalogs = payload.catalog_id != undefined ? [ payload.catalog_id ] : [ this.actualCatalog.id ]

          if (this.product_extras) { payload.product_extras = this.product_extras.map(product => product.value) }
          if (this.product_participants) { payload.product_participants = this.product_participants.map(participant => participant.value) }

          this.$emit("submit", payload);
        } else {
          this.notifyWarning(i18n.t("shops.errors.warning"), i18n.t("shops.errors.product_validation_failed"));
          if (!payload.name) this.errorProductName = true;
          if (!payload.type.name) this.errorProductType = true;
          if (!payload.tax.name) this.errorTax = true;
          if (!payload.catalogs.length) this.errorCatalog = true;
        }
      });
    },

    selectAllExtras() {
      this.product_extras = this.productList.filter((product) => product.type.id === 1 && (product.catalogs[0].catalog_id === this.product.catalog_id || product.catalogs[0].catalog_id === this.actualCatalog.id))
        .map((product) => ({ label: product.name, value: product.id }))
    },
    
    deselectAllExtras() {
      this.product_extras = []
    },

    selectAllParticipants() {
      this.product_participants = this.participantList.filter((participant) => participant.catalogs[0].catalog_id === this.product.catalog_id || participant.catalogs[0].catalog_id === this.actualCatalog.id)
        .map((participant) => ({ label: participant.name, value: participant.id }))
    },

    deselectAllParticipants() {
      this.product_participants = []
    },

    selectCatalog(option) {
      this.product.catalog_id = option === null ? option : option.value
    },

    selectProductType(option) {
      this.product.product_type_id = option === null ? option : option.value;
    },

    selectTax(option){
      this.product.tax_id = option === null ? option : option.value;
    },

    selectProductList(option){
      this.product.requirements.product = option === null ? option : option.value;
    },

    selectParticipantList(option){
      this.product.requirements.participant = option === null ? option : option.value;
    },
  },

  async created() {
    this.weekdays = weekdays()
    this.product = JSON.parse(JSON.stringify(this.value))
    
    if (this.product.products) {
      this.product_extras = this.product.products.map(product => ({label: product.name, value: product.id}))
    }
    
    if (this.product.participants) {
      this.product_participants = this.product.participants.map(participant => ({label: participant.name, value: participant.id}))
    }

    this.product.days = this.product.days ? this.product.days : 0;

    this.product.participant =  await this.participantById(this.product.requirements.participant) || ""

    this.product.product =  await this.productById(this.product.requirements.product) || ""

    if (this.product.catalogs.name != undefined) {
      for (const catalog of this.catalogList) {
        if (catalog.name == this.product.catalogs.name) {
          this.actualCatalog = catalog;
        }
      }
    }
  }
};
</script>

<style scope>
  .minwidthSelect {
    min-width: 250px;
  }
</style>
