var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-form" }, [
    _c("div", { staticClass: "vx-row mb-6" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/2 mb-5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.active")))
          ]),
          _c("vs-switch", {
            staticClass: "mt-3",
            attrs: { type: "boolean" },
            model: {
              value: _vm.product.active,
              callback: function($$v) {
                _vm.$set(_vm.product, "active", $$v)
              },
              expression: "product.active"
            }
          })
        ],
        1
      )
    ]),
    _c(
      "div",
      { staticClass: "vx-row mb-6" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  directives: [
                    {
                      name: "validate",
                      rawName: "v-validate",
                      value: "required",
                      expression: "'required'"
                    }
                  ],
                  staticClass: "w-full",
                  attrs: {
                    name: "productName",
                    danger:
                      _vm.errorProductName && _vm.errors.has("productName"),
                    "danger-text": _vm.$t("shops.errors.name_is_required")
                  },
                  model: {
                    value: _vm.product.name,
                    callback: function($$v) {
                      _vm.$set(_vm.product, "name", $$v)
                    },
                    expression: "product.name"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "productName" + language },
                  model: {
                    value: _vm.product.translations[language].name,
                    callback: function($$v) {
                      _vm.$set(_vm.product.translations[language], "name", $$v)
                    },
                    expression: "product.translations[language].name"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c(
      "div",
      { staticClass: "vx-row mb-6 mt-10" },
      _vm._l(_vm.languages(), function(language, index) {
        return _c(
          "div",
          { key: index, staticClass: "vx-col w-1/5" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.description_" + language)))
            ]),
            _vm.langRequired(language)
              ? _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "productDescription" },
                  model: {
                    value: _vm.product.description,
                    callback: function($$v) {
                      _vm.$set(_vm.product, "description", $$v)
                    },
                    expression: "product.description"
                  }
                })
              : _c("vs-input", {
                  staticClass: "w-full",
                  attrs: { name: "productDescription" + language },
                  model: {
                    value: _vm.product.translations[language].description,
                    callback: function($$v) {
                      _vm.$set(
                        _vm.product.translations[language],
                        "description",
                        $$v
                      )
                    },
                    expression: "product.translations[language].description"
                  }
                })
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "vx-row mb-6 mt-10" }, [
      _c(
        "div",
        { staticClass: "vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.external_id_info")))
          ]),
          _c("vs-input", {
            staticClass: "w-full",
            attrs: { name: "productExternalID" },
            model: {
              value: _vm.product.external_id,
              callback: function($$v) {
                _vm.$set(_vm.product, "external_id", $$v)
              },
              expression: "product.external_id"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-6 vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.catalog.0")))
          ]),
          _vm.catalogList.length > 0
            ? _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "w-full select-auto",
                class: {
                  "danger-border-select":
                    _vm.errorCatalog && _vm.errors.has("catalog")
                },
                attrs: {
                  options: _vm.catalogList.map(function(catalog) {
                    return { label: catalog.name, value: catalog.id }
                  }),
                  value: _vm.actualCatalog,
                  "danger-text": _vm.$t("shops.errors.catalog_is_required"),
                  danger: _vm.errorCatalog && _vm.errors.has("catalog"),
                  name: "catalog",
                  placeholder: _vm.$t("shops.select_a_catalog")
                },
                on: {
                  input: function(option) {
                    return _vm.selectCatalog(option)
                  }
                },
                model: {
                  value: _vm.product.catalogs.name,
                  callback: function($$v) {
                    _vm.$set(_vm.product.catalogs, "name", $$v)
                  },
                  expression: "product.catalogs.name"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-6 vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.type")))
          ]),
          _vm.productTypes.length > 0
            ? _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "w-full select-large",
                class: {
                  "danger-border-select":
                    _vm.errorProductType && _vm.errors.has("product_type")
                },
                attrs: {
                  options: _vm.productTypes.map(function(productType) {
                    return { label: productType.name, value: productType.id }
                  }),
                  value: _vm.product.type.id,
                  name: "product_type",
                  placeholder: _vm.$t("shops.select_a_product_type"),
                  "danger-text": _vm.$t(
                    "shops.errors.product_type_is_required"
                  ),
                  danger: _vm.errorProductType && _vm.errors.has("type")
                },
                on: {
                  input: function(option) {
                    return _vm.selectProductType(option)
                  }
                },
                model: {
                  value: _vm.product.type.name,
                  callback: function($$v) {
                    _vm.$set(_vm.product.type, "name", $$v)
                  },
                  expression: "product.type.name"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-6 vx-col w-1/5" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.tax")))
          ]),
          _vm.taxList.length > 0
            ? _c("v-select", {
                directives: [
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required",
                    expression: "'required'"
                  }
                ],
                staticClass: "w-full select-large",
                class: {
                  "danger-border-select": _vm.errorTax && _vm.errors.has("tax")
                },
                attrs: {
                  options: _vm.taxList.map(function(tax) {
                    return { label: tax.name, value: tax.id }
                  }),
                  value: _vm.value.tax.name,
                  name: "tax",
                  placeholder: _vm.$t("shops.select_a_tax"),
                  "danger-text": _vm.$t("shops.errors.tax_is_required"),
                  danger: _vm.errorTax && _vm.errors.has("tax")
                },
                on: {
                  input: function(option) {
                    return _vm.selectTax(option)
                  }
                },
                model: {
                  value: _vm.product.tax.name,
                  callback: function($$v) {
                    _vm.$set(_vm.product.tax, "name", $$v)
                  },
                  expression: "product.tax.name"
                }
              })
            : _vm._e()
        ],
        1
      ),
      _vm.showDaysDuration
        ? _c(
            "div",
            { staticClass: "mb-6 vx-col w-1/5" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.days")))
              ]),
              _c("vs-input-number", {
                attrs: { name: "days" },
                model: {
                  value: _vm.product.days,
                  callback: function($$v) {
                    _vm.$set(_vm.product, "days", $$v)
                  },
                  expression: "product.days"
                }
              })
            ],
            1
          )
        : _vm._e()
    ]),
    _c("div", { staticClass: "mb-6 vx-col w-full" }, [
      _c("div", { staticClass: "w-full" }, [
        _c("label", { staticClass: "vs-input--label" }, [
          _c("b", [_vm._v(_vm._s(_vm.$t("shops.days_on_sale")))])
        ])
      ]),
      _c(
        "div",
        { staticClass: "vx-row mb-6" },
        _vm._l(_vm.weekdays, function(day, index) {
          return _c(
            "div",
            { key: index, staticClass: "vx-col w-1/7" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops." + day)))
              ]),
              _c("vs-switch", {
                staticClass: "mt-3",
                attrs: { type: "boolean" },
                model: {
                  value: _vm.product.restrictions[day],
                  callback: function($$v) {
                    _vm.$set(_vm.product.restrictions, day, $$v)
                  },
                  expression: "product.restrictions[day]"
                }
              })
            ],
            1
          )
        }),
        0
      )
    ]),
    _c("div", { staticClass: "mb-6 vx-col w-full" }, [
      _c("div", { staticClass: "w-full" }, [
        _c("label", { staticClass: "vs-input--label" }, [
          _c("b", [_vm._v(_vm._s(_vm.$t("shops.requirements")))])
        ])
      ]),
      _c("div", { staticClass: "vx-row mb-6" }, [
        _c(
          "div",
          { staticClass: "vx-col w-1/7" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.needs_date")))
            ]),
            _c("vs-switch", {
              staticClass: "mt-3",
              attrs: { type: "boolean" },
              model: {
                value: _vm.product.requirements.date,
                callback: function($$v) {
                  _vm.$set(_vm.product.requirements, "date", $$v)
                },
                expression: "product.requirements.date"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/7" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.needs_keycard")))
            ]),
            _c("vs-switch", {
              staticClass: "mt-3",
              attrs: { type: "boolean" },
              model: {
                value: _vm.product.requirements.keycard,
                callback: function($$v) {
                  _vm.$set(_vm.product.requirements, "keycard", $$v)
                },
                expression: "product.requirements.keycard"
              }
            })
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "vx-col w-1/7" },
          [
            _c("label", { staticClass: "vs-input--label" }, [
              _vm._v(_vm._s(_vm.$t("shops.needs_photo")))
            ]),
            _c("vs-switch", {
              staticClass: "mt-3",
              attrs: { type: "boolean" },
              model: {
                value: _vm.product.requirements.photo,
                callback: function($$v) {
                  _vm.$set(_vm.product.requirements, "photo", $$v)
                },
                expression: "product.requirements.photo"
              }
            })
          ],
          1
        )
      ])
    ]),
    _vm.product.type.id === 3 || _vm.product.product_type_id === 3
      ? _c("div", [
          _c("div", { staticClass: "mb-6 vx-col w-full" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("shops.products.title")))])
              ])
            ]),
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("v-select", {
                  staticClass: "minwidthSelect",
                  attrs: {
                    multiple: "",
                    options: _vm.productsFiltered,
                    placeholder: _vm.$t("shops.select_extras")
                  },
                  model: {
                    value: _vm.product_extras,
                    callback: function($$v) {
                      _vm.product_extras = $$v
                    },
                    expression: "product_extras"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pt-3" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { type: "filled" },
                    on: { click: _vm.selectAllExtras }
                  },
                  [_vm._v(_vm._s(_vm.$t("shops.select_all")))]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-4",
                    attrs: { type: "filled" },
                    on: { click: _vm.deselectAllExtras }
                  },
                  [_vm._v(_vm._s(_vm.$t("shops.deselect_all")))]
                )
              ],
              1
            )
          ]),
          _c("div", { staticClass: "mb-6 vx-col w-full" }, [
            _c("div", { staticClass: "w-full" }, [
              _c("label", { staticClass: "vs-input--label" }, [
                _c("b", [_vm._v(_vm._s(_vm.$t("shops.participants.title")))])
              ])
            ]),
            _c(
              "div",
              { staticClass: "inline-block" },
              [
                _c("v-select", {
                  staticClass: "minwidthSelect",
                  attrs: {
                    multiple: "",
                    options: _vm.participantsFiltered,
                    placeholder: _vm.$t("shops.select_participants")
                  },
                  model: {
                    value: _vm.product_participants,
                    callback: function($$v) {
                      _vm.product_participants = $$v
                    },
                    expression: "product_participants"
                  }
                })
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "pt-3" },
              [
                _c(
                  "vs-button",
                  {
                    attrs: { type: "filled" },
                    on: { click: _vm.selectAllParticipants }
                  },
                  [_vm._v(_vm._s(_vm.$t("shops.select_all")))]
                ),
                _c(
                  "vs-button",
                  {
                    staticClass: "ml-4",
                    attrs: { type: "filled" },
                    on: { click: _vm.deselectAllParticipants }
                  },
                  [_vm._v(_vm._s(_vm.$t("shops.deselect_all")))]
                )
              ],
              1
            )
          ])
        ])
      : _vm._e(),
    _c(
      "div",
      { staticClass: "vx-col 1/3" },
      [
        _c(
          "vs-button",
          {
            staticClass: "mt-base",
            attrs: { type: "filled" },
            on: {
              click: function($event) {
                return _vm.validateAndSubmit()
              }
            }
          },
          [_vm._v(_vm._s(_vm.$t("shops.save")))]
        )
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }